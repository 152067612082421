

export const  formatDate = (userDate) => {
    // format from M/D/YYYY to YYYYMMDD
    return (new Date(userDate).toJSON().slice(0,10).split('-').reverse().join('-'));
}

export const redirectToUrl = (url) => {
  window.location.assign(url)
}
