import { Col, Container, Row } from "reactstrap"
import { FaAngleLeft } from "react-icons/fa";
import NavbarComp from "../../../../Components/Navbar";
import './style.css'
import Masthead from "../../../../Components/Masthead";

const DogCareFormData = ({data, step}) => {
   const careFormData = data.dog_info_form
    return(
        <div>
            <Row>
                <Col md="12">
                    <div className="navbar">
                    <Masthead />
                        </div>
                </Col>
            </Row>

            <Container>
            <div className="back">
                <span
                    onClick={() => step(0)}
                    style={{ cursor: "pointer"}}
                >
                    <FaAngleLeft /> Back
                    </span>
                </div>
            <h2 className="text-center w-100">Basic information</h2>
            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">1. What kind of day does the dog need?</label>
                    <p className="dogform-text">{careFormData?.kind_of_day_dog_need || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">1(a) Please share more details</label>
                    <p className="dogform-text">{careFormData?.kind_of_day_dog_need_details || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">How many times does the dog need to be given food in a day?</label>
                    <p className="dogform-text">{careFormData?.no_of_meals_in_a_day || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Please share more details about what the dog should be given for each meal</label>
                    <p className="dogform-text">{careFormData?.meal_additional_info || '-'}</p>
                </Col>
            </Row>

            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">Anything specific he/she is not allowed to eat or is allergic to? Please provide details</label>
                    <p className="dogform-text">{careFormData?.food_to_avoid || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Will the dog be on heat during the booking?</label>
                    <p className="dogform-text">{careFormData?.is_dog_on_heat || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Is the dog toilet trained?</label>
                    <p className="dogform-text">{careFormData?.is_toilet_trained || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Please explain anything else that must be kept in mind about the dog's toilet habits?</label>
                    <p className="dogform-text">{careFormData?.toilet_habits || '-'}</p>
                </Col>
            </Row>

            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">How many walks does the dog need in a day?</label>
                    <p className="dogform-text">{careFormData?.no_of_walks_in_a_day || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label"> 2. What kind of walk does the dog need?</label>
                    <p className="dogform-text">{careFormData?.kind_of_walk_dog_need || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">2(a) Please share more details</label>
                    <p className="dogform-text">{careFormData?.kind_of_walk_dog_need_details || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">How long should each walk be? Please specify for each walk so that the dogsitter has all the information needed.</label>
                    <p className="dogform-text">{careFormData?.walk_duration_info || '-'}</p>
                </Col>
            </Row>


            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">Is there anything the dog sitter needs to be careful about when walking the dog?Please explain.</label>
                    <p className="dogform-text">{careFormData?.walk_additional_info || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Is the dog trained to walk on the leash? Please explain</label>
                    <p className="dogform-text">{careFormData?.trained_walk_on_leash || '-'}</p>
                </Col>
            </Row>

            <h2 className="text-center w-100">Knowing the Dog Better</h2>
            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">Is the dog allowed on the sofas at home?</label>
                    <p className="dogform-text">{careFormData?.is_dog_allowed_on_sofa || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label"> Is the dog used to sleeping on the bed?</label>
                    <p className="dogform-text">{careFormData?.is_dog_used_to_sleep_on_bed || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Is the dog used to sleeping in the same room?</label>
                    <p className="dogform-text">{careFormData?.is_dog_used_to_sleep_in_same_room || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Does the dog get along with other dogs?</label>
                    <p className="dogform-text">{careFormData?.get_along_with_other_dogs || '-'}</p>
                </Col>
            </Row>

            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">Are there any specific situations he/she is good/not good in? Please explain</label>
                    <p className="dogform-text">{careFormData?.anything_dog_not_good_in || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Is the dog comfortable with cats?</label>
                    <p className="dogform-text">{careFormData?.comfortable_with_cats || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Please explain(comfortable with cats)</label>
                    <p className="dogform-text">{careFormData?.comfortable_with_cats_info || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">How does the dog usually react to strangers? Please explain</label>
                    <p className="dogform-text">{careFormData?.react_with_strangers || '-'}</p>
                </Col>
            </Row>

            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">Is there anything specific that should be avoided when the dog is around people?</label>
                    <p className="dogform-text">{careFormData?.things_to_avoid_when_around_people || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label"> Is the dog possessive about his/her toys or food?</label>
                    <p className="dogform-text">{careFormData?.is_possessive_with_toys_or_food || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Please explain(comfortable with cats)</label>
                    <p className="dogform-text">{careFormData?.comfortable_with_cats_info || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Does he/she get aggressive about anything?</label>
                    <p className="dogform-text">{careFormData?.is_aggressive_about_anything || '-'}</p>
                </Col>
            </Row>

            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">Is there anything that needs to be put away or kept hidden when the dog isalone at home?</label>
                    <p className="dogform-text">{careFormData?.anything_to_hide_from_dog || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Do you remove the dog's collar at home?</label>
                    <p className="dogform-text">{careFormData?.remove_dog_collar_at_home || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Does the dog chew on,  scratch or otherwise damage furniture,  electronics or other household objects?</label>
                    <p className="dogform-text">{careFormData?.dog_damages_things || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Please share a list of the common words/verbal signals the dog understands inwhichever language you use so that the dog sitter is aware of these. Please explain if required.</label>
                    <p className="dogform-text">{careFormData?.common_words_or_signals || '-'}</p>
                </Col>
            </Row>

            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">Is the dog comfortable travelling by car?</label>
                    <p className="dogform-text">{careFormData?.comfortable_travelling_in_car || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Is there any special preparation needed for him/ her to travel by car? Please explain </label>
                    <p className="dogform-text">{careFormData?.special_preparation_to_travel_in_car || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Are there any habits or patterns of the dog which the dog sitter should know about?</label>
                    <p className="dogform-text">{careFormData?.any_habit_or_pattern_of_dog || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">How many times a day do you want to be updated about the dog?</label>
                    <p className="dogform-text">{careFormData?.no_of_updates_about_dog_in_a_day || '-'}</p>
                </Col>
            </Row>

            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">If you are not able to pick up the dog and have not informed us, please share the name, telephone number and address of an alternate guardian the dog can be handed over to </label>
                    <p className="dogform-text">{careFormData?.address_info_to_leave_dog_if_not_available || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Please share the name, telephone number and address of someone we can contact in case we cannot reach you in an emergency </label>
                    <p className="dogform-text">{careFormData?.emergency_details || '-'}</p>
                </Col>
            </Row>

            <h2 className="text-center w-100">Medical Information</h2>
            <Row className="mx-5 mt-5">
                <Col md="3">
                    <label className="dogform-label">Will the dog's vaccinations be up to date at the time of the booking?</label>
                    <p className="dogform-text">{careFormData?.vaccination_up_to_date || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">If other, please explain (vaccinations)</label>
                    <p className="dogform-text">{careFormData?.vaccination_additional_info || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Does the dog need to be given any medication while you are away?</label>
                    <p className="dogform-text">{careFormData?.any_medication_to_dog || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">If other, please explain(medication info)</label>
                    <p className="dogform-text">{careFormData?.medication_additional_info || '-'}</p>
                </Col>
            </Row>

            <Row className="m-5">
                <Col md="3">
                    <label className="dogform-label">Has the dog had any previous health problems or medical conditions? Please explain</label>
                    <p className="dogform-text">{careFormData?.any_health_issues || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Please share details of your preferred veterinary doctor in case of a situation where a vet is needed</label>
                    <p className="dogform-text">{careFormData?.preferred_veterinary_doctor || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">In case of an emergency situation, what is the upper limit you authorise the dog sitter to spend?</label>
                    <p className="dogform-text">{careFormData?.upper_limit_to_spend_on_medication || '-'}</p>
                </Col>
                <Col md="3">
                    <label className="dogform-label">Is the dog covered by a specific medical insurance? Please share details</label>
                    <p className="dogform-text">{careFormData?.any_medical_insurance || '-'}</p>
                </Col>
            </Row>
            </Container>

        </div>
    )
}

export default DogCareFormData