import { Avatar, Box, Menu, MenuItem, Tooltip, Typography } from "@mui/material"
import { assests } from "../../Assests/assets"
import './style.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { clearLocal } from "../../Utils/localStorage";
import { useNavigate } from "react-router-dom";
import MobileMasthead from "../MobileMasthead";
import { redirectToUrl } from "../../Utils/common";


const Masthead = () => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const navigate = useNavigate()
    const isAuthorised = localStorage.getItem('token')

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
      };
    
      const handleCloseUserMenu = () => {
        setAnchorElUser(null);
      };

      const handleLogout = () => {
        clearLocal()
        navigate('/login')
    }

    return(
        <>
        <Box className="new-desktop-masthead" sx={{padding: '1.5em 2em 1em 2em', display: 'flex', fontFamily: "Roboto, Sans-serif"}}>
            <Box sx={{width: '17%'}}>
                <img style={{width: '61%', cursor: 'pointer'}} onClick={() => redirectToUrl('https://dogstays.lu')} src={assests.dogstaysLogo} alt='DogStays'/>
            </Box>
            <Box sx={{width: '65.332%', margin: 'auto', mb: 0}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                   {isAuthorised && <Box>
                        <div className="menu-header">
                            <p onClick={() => navigate('/sitter/home')} style={{lineHeight: '24px'}}>Home</p>
                        </div>
                    </Box>}
                    <Box>
                        <div className="menu-header">
                            <p onClick={() => redirectToUrl('www.dogstays.lu/services')}>Services</p>
                            {<ArrowDropDownIcon sx={{color: '#FF9315', marginLeft: '5px'}}/>}
                        </div>
                        <div className="menu-header-dropdown highlight">
                            <ul>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/services/#overnight')}>Overnight</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/services/#daycare')}>Daycare</li>
                            </ul>
                        </div>
                    </Box>
                    <Box>
                        <div className="menu-header">
                            <p onClick={() => redirectToUrl('https://www.dogstays.lu/book')}>Book a Dog Sitter</p>
                            {<ArrowDropDownIcon sx={{color: '#FF9315', marginLeft: '5px'}}/>}
                        </div>
                        <div className="menu-header-dropdown">
                            <ul>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/book/process')}>Process</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/book/pricing')}>Pricing</li>
                            </ul>
                        </div>
                    </Box>
                    {!isAuthorised && <Box>
                        <div className="menu-header">
                            <p onClick={() => redirectToUrl('https://www.dogstays.lu/dogsitters')}>For Dog Sitters</p>
                            {<ArrowDropDownIcon sx={{color: '#FF9315', marginLeft: '5px'}}/>}
                        </div>
                        <div className="menu-header-dropdown">
                            <ul>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/dogsitters/#application-process')}>Our Application Process</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/dogsitters/#testimonials')}>Dog Sitter Testimonials</li>
                            </ul>
                        </div>
                    </Box>}
                    <Box>
                        <div className="menu-header">
                            <p onClick={() => redirectToUrl('https://www.dogstays.lu/faqs')}>FAQ</p>
                            {<ArrowDropDownIcon sx={{color: '#FF9315', marginLeft: '5px'}}/>}
                        </div>
                        <div className="menu-header-dropdown">
                            <ul>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/faqs-parents')}>Dog Parent FAQs</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/faqssitters')}>Dog Sitter FAQs</li>
                            </ul>
                        </div>
                    </Box>
                    <Box>
                        <div className="menu-header">
                            <p>More</p>
                            {<ArrowDropDownIcon sx={{color: '#FF9315', marginLeft: '5px'}}/>}
                        </div>
                        <div className="menu-header-dropdown">
                            <ul>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/media-coverage')}>Media Coverage</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/about-us')}>About Us</li>
                                <li onClick={() => redirectToUrl('https://www.dogstays.lu/contact')}>Contact Us</li>
                            </ul>
                        </div>
                    </Box>
                </div>
            </Box>
            <Box className="user-profile-data">
                <img className="luxembourg-icon" src={assests.Luxembourg}></img>
                {!isAuthorised ? <button className="login-sitter-btn" onClick={() => navigate('/login')}>Login as Dog sitter</button> :
                <Box>
                    <Tooltip>
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml:2, mt:1}}>
                          <Avatar sx={{ border: "1px solid #80808075" }} />
                      </IconButton>
                    </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                      <MenuItem>
                        <Typography
                          onClick={handleLogout}
                          textAlign="center"
                        >
                          Logout
                        </Typography>
                      </MenuItem>
                  </Menu>
                </Box>}
            </Box>
        </Box>
        <MobileMasthead/>
        </>
        
    )
}

export default Masthead