import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, FormGroup, Input, Label, Modal, ModalBody, Row } from "reactstrap";
import { DynamicButton } from "../../../../../Components/DynamicButton";
import GlobalNav from '../../../../../Components/GlobalNavbar'
import "./style.css";
import Masthead from "../../../../../Components/Masthead";

const StartUserApplication = () => {

  const navigate = useNavigate();
  return (
    <>
     <Masthead/>
    <Container fluid className="contain">
      <Row className="parent-wrap">
        <div className="start-application-wrapper">
          <div className="mb-4">
          <p className="m-0">Already a dog sitter with DogStay's? Use button, to login</p>
          <DynamicButton
            style={{width:'30%', marginTop:'10px'}}
            createBtnText="Login"
            clickFunction={() => navigate("/login")}
          />
          </div>
          <h1>Joining The DogStays Sitter Network</h1>
          <p>
            Hello! Thank you for your interest in joining our community. We are
            a community of dog sitters from different backgrounds! If you
            understand dogs, love them and have experience caring for them, you
            are in the right place :
          </p>
          <p>
            Through our network you can care for dogs in your own home or the
            dog parent's home. Each dog sitter in our network goes through an
            application process. This is to make sure we are doing our best for
            the dogs that book with us. This process is divided into 2 parts:{" "}
          </p>
          <ul className="info-list">
            <li>
              First, we will ask you for some basic information including your
              experience with dogs and other animals.{" "}
            </li>
            <li>
              We will review this information and send you an email with access
              to the second part of the process{" "}
            </li>
            <li>
              In this second part we will ask you about the kind of dogs you
              would like to care for. This will help us only connect you with
              dogs that are suited to your preferences
            </li>
            <li>
              In this part you will also need to upload some documents that each
              dog sitter in the community shares with us. This is only a
              security measure and these documents will not be shared with
              anybody.{" "}
            </li>
          </ul>
          <p>
            You can start the first part by clicking the button below. We look
            forward to receiving your application and getting in touch with you
          </p>

          <DynamicButton
            style={{width:'30%'}}
            createBtnText="Start"
            clickFunction={() => navigate("/applicationform")}
          />
        </div>
      </Row>
    </Container>
    </>
  );
};

export default StartUserApplication;
